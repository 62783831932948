const menuItemsService = [
  {
    title: "Restaurants",
    link: "/restaurants",
    icon: "mdi-silverware-fork-knife",
    active: true,
  },
  {
    title: "Leisures",
    link: "/leisures",
    icon: "mdi-sticker-emoji",
    active: true,
  },
  // { title: "Hotels", link: "/hotels", icon: "mdi-bed", active: true },
  { divider: true, inset: true },
  // {
  //   title: "Pharmacies",
  //   link: "/pharmaceutics",
  //   icon: "mdi-pharmacy",
  //   active: true,
  // },
  {
    title: "Laboratories",
    link: "/laboratories",
    icon: "mdi-eyedropper",
    active: true,
  },
  // {
  //   title: "Transport",
  //   link: "/transportations",
  //   icon: "mdi-taxi",
  //   active: true,
  // },
  { title: "Vineyards", link: "/vineyards", icon: "mdi-tree", active: true },
  {
    title: "Recovery Houses",
    link: "/houses",
    icon: "mdi-home-outline",
    active: true,
  },
];

const menuItemsAbout = [
  {
    title: "About",
    link: "/about",
    icon: "mdi-account-multiple",
    active: true,
  },
  // {
  //   title: "Join BHT",
  //   link: "/jointobhc",
  //   icon: "mdi-link-variant",
  //   active: true,
  // },
  { title: "Contacts", link: "/contacts", icon: "mdi-email", active: true },
  // {
  //   title: "Alliances",
  //   link: "/alliances",
  //   icon: "mdi-share-variant",
  //   active: true,
  // },
];

const menuItemsLang = [
  {
    title: "English",
    link: "/under-construction", // #lang-english
  },
  {
    title: "Español",
    link: "/under-construction", // #lang-spanish
  },
];

const menuItemsVertical = [
  /*{
        icon: 'mdi-hospital-building',
        title: 'Hospitales y Clínicas',
        link: '/hospitals-clinics',
        active: false,
    },
    {
        icon: 'mdi-doctor',
        title: 'Servicios Médicos',
        link: '/medical-services',
        active: false,
    },*/
  {
    icon: "mdi-tag",
    title: "Other services",
    active: false,
    children: [
      {
        title: "Restaurants",
        link: "/restaurants",
        icon: "mdi-silverware-fork-knife",
        active: true,
      },
      {
        title: "Leisures",
        link: "/leisures",
        icon: "mdi-sticker-emoji",
        active: true,
      },
      // { title: "Hoteles", link: "/hotels", icon: "mdi-bed", active: true },
      {
        title: "Pharmacies",
        link: "/pharmaceutics",
        icon: "mdi-pharmacy",
        active: true,
      },
      {
        title: "Laboratories",
        link: "/laboratories",
        icon: "mdi-eyedropper",
        active: true,
      },
      {
        title: "Transport",
        link: "/transportations",
        icon: "mdi-taxi",
        active: true,
      },
      {
        title: "Vineyards",
        link: "/vineyards",
        icon: "mdi-tree",
        active: true,
      },
      {
        title: "Recovery Houses",
        link: "/houses",
        icon: "mdi-home-outline",
        active: true,
      },
    ],
  },
  {
    icon: "mdi-account-multiple",
    title: "Us",
    active: false,
    children: [
      {
        title: "About us",
        link: "/about",
        icon: "mdi-face",
        active: true,
      },
      // {
      //   title: "Join BHT",
      //   link: "/jointobhc",
      //   icon: "mdi-link-variant",
      //   active: true,
      // },
      {
        title: "Contacts",
        link: "/contacts",
        icon: "mdi-email",
        active: true,
      },
      // {
      //   title: "Alliances",
      //   link: "/alliances",
      //   icon: "mdi-share-variant",
      //   active: true,
      // },
    ],
  },
];

export { menuItemsService, menuItemsAbout, menuItemsLang, menuItemsVertical };
